import Api from './Api';

const checkPaypalPayment = (payload) => {
    return Api.postRequest({
        endpoint: '/paypal/check',
        data: payload
    });
};

export default {
    checkPaypalPayment,
};
